<template>
  <div>
    <v-card class="card pa-2">
      <div class="title_box py-2 f-11 mb-8">検索条件</div>
      <div class="disno-sm">
        <div class="pl-4 text-left f-12 bold mb-3">
          エリア
          <span class="pl-6"
            ><span class="f-red" v-if="selec">選択中</span
            ><span class="f-gray f-normal" v-else>指定なし</span></span
          >
        </div>
        <v-btn
          color="blue"
          outlined
          large
          @click="dialogOpen"
          class="box-shadow"
          >エリアの選択・確認</v-btn
        >
      </div>
      <div class="pl-4 text-left f-12 bold mt-4 mb-2">
        <span v-if="tradeType == '売買'"> 価格 </span>
        <span v-else> 賃料 </span>
      </div>
      <v-select
        v-model="bPrice"
        :items="bPrices"
        item-text="text"
        item-value="val"
        outlined
        class="price_select f-09 float-left"
      />
      <div class="f-16 relative bar float-left px-2 disno-md">～</div>
      <div class="f-16 relative bar float-left px-2 dis-sm">～</div>
      <div class="clear dis-md disno-sm" />
      <v-select
        v-model="tPrice"
        :items="tPrices"
        item-text="text"
        item-value="val"
        outlined
        class="price_select f-09 relative float-left"
      />
      <div class="clear" />
      <div
        class="f-red r-08 relative text-left pl-1"
        style="height: 2em; top: -1.5em"
        v-show="bPrice > tPrice"
      >
        金額設定が間違っています
      </div>
      <div class="pl-4 text-left f-12 bold mb-4" style="top: -4em">現況</div>
      <div class="select_box text-left">
        <div class="inline-block">
          <v-checkbox
            v-model="empty"
            :label="
              '賃貸中を除く (' + Number(emptyCount).toLocaleString() + ')'
            "
            class="my-0 py-0"
          ></v-checkbox>
        </div>
      </div>

      <div class="pl-4 text-left f-12 bold mb-8 mt-5">間取り</div>
      <v-row class="select_box text-left">
        <v-col cols="6" v-for="(f, i) in floors" :key="i" class="py-0 my-0">
          <div class="inline-block">
            <v-checkbox
              v-model="floor[i]"
              :label="f + ' (' + Number(roomCount[i]).toLocaleString() + ')'"
              class="my-0 py-0"
              @click="floorChange()"
              :disabled="roomCount[i] == 0"
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
      <div class="pl-4 text-left f-12 bold mb-5 mt-5">面積</div>
      <div class="select_box">
        <v-select
          v-model="area1"
          :items="areas"
          item-text="text"
          item-value="val"
          outlined
          class="area_select"
        />
      </div>
      <div class="pl-4 text-left f-12 bold mb-5 mt-5">建物構造</div>
      <div class="select_box text-left">
        <div class="inline-block">
          <v-checkbox
            v-model="frame[i - 1]"
            :label="
              frames[i - 1] +
              ' (' +
              Number(frameCount[i - 1]).toLocaleString() +
              ')'
            "
            v-for="i in 4"
            :key="i"
            class="my-0 py-0"
            @click="frameChange()"
            :disabled="frameCount[i - 1] == 0"
          ></v-checkbox>
        </div>
      </div>

      <div class="pl-4 text-left f-12 bold mb-5 mt-5">築年数</div>
      <div class="select_box">
        <v-select
          v-model="year"
          :items="years"
          item-text="text"
          item-value="val"
          outlined
          class="area_select"
        />
      </div>
    </v-card>
  </div>
</template>


<script>
import NarrowMix from "@/mixins/narrow";
export default {
  mixins: [NarrowMix],
  data() {
    return {
      floors: [
        "ワンルーム",
        "1R",
        "1K",
        "1DK",
        "1LDK",
        "2K",
        "2DK",
        "2LDK",
        "3K",
        "3DK",
        "3LDK",
        "4K",
        "4DK",
        "4LDK以上",
      ],
      areas: [
        { text: "指定なし", val: 0 },
        { text: "20㎡以上", val: 20 },
        { text: "25㎡以上", val: 25 },
        { text: "30㎡以上", val: 30 },
        { text: "35㎡以上", val: 35 },
        { text: "40㎡以上", val: 40 },
        { text: "45㎡以上", val: 45 },
        { text: "50㎡以上", val: 50 },
        { text: "55㎡以上", val: 55 },
        { text: "60㎡以上", val: 60 },
        { text: "65㎡以上", val: 65 },
        { text: "70㎡以上", val: 70 },
        { text: "75㎡以上", val: 75 },
        { text: "80㎡以上", val: 80 },
        { text: "85㎡以上", val: 85 },
        { text: "90㎡以上", val: 90 },
        { text: "95㎡以上", val: 95 },
        { text: "100㎡以上", val: 100 },
        { text: "120㎡以上", val: 120 },
        { text: "150㎡以上", val: 150 },
        { text: "200㎡以上", val: 200 },
      ],
      frames: ["鉄筋系【RC】", "鉄骨系【SRC】", "木造", "その他"],
      years: [
        { text: "指定なし", val: 0 },
        { text: "1年以内", val: 1 },
        { text: "3年以内", val: 3 },
        { text: "5年以内", val: 5 },
        { text: "10年以内", val: 10 },
        { text: "15年以内", val: 15 },
        { text: "20年以内", val: 20 },
        { text: "25年以内", val: 25 },
        { text: "30年以内", val: 30 },
        { text: "35年以内", val: 35 },
        { text: "40年以内", val: 40 },
      ],
      roomCount: [],
      ownerCount: 0,
      emptyCount: 0,
      frameCount: [],
      selec: false,
      newOld: "",
      buildType: "",
      tradeType: "",
      tPrices: [],
      bPrices: [],
    };
  },
  props: {
    City: Array,
  },
  mounted() {
    this.priceChange();
    this.routeChange();
  },
  methods: {
    priceChange() {
      let p = [];

      if (this.$route.path.indexOf("/rent") !== -1) {
        for (let i = 1; i < 20; i++) {
          p.push({ text: `${i}万円`, val: i });
          p.push({ text: `${i + 0.5}万円`, val: i + 0.5 });
        }
        for (let i = 4; i <= 11; i++) {
          p.push({ text: `${(i - 1) * 10 - 5}万円`, val: (i - 1) * 10 - 5 });
          p.push({ text: `${(i - 1) * 10}万円`, val: (i - 1) * 10 });
        }
      } else {
        for (let i = 0; i <= 16; i++) {
          let a = i * 50 + 200;
          p.push({ text: `${a}万円`, val: a });
        }
        for (let i = 17; i <= 33; i++) {
          let a = (i - 16) * 500 + 1000;
          p.push({ text: `${a.toLocaleString()}万円`, val: a });
        }
        p.push({ text: `1億円`, val: 100000000 });
      }

      const tPrices = JSON.parse(JSON.stringify(p));
      tPrices.unshift({ text: "上限なし", val: 10000000000 });

      const bPrices = JSON.parse(JSON.stringify(p));
      bPrices.unshift({ text: "下限なし", val: 0 });

      this.tPrices = tPrices;
      this.bPrices = bPrices;
    },
    floorChange() {
      this.$emit("floorChange", this.floor);
    },
    frameChange() {
      this.$emit("frameChange", this.frame);
    },
    dialogOpen() {
      this.$emit("dialogOpen");
    },
    routeChange() {
      if (this.$route.path.indexOf("/new") != -1) {
        this.newOld = "新築";
      } else {
        this.newOld = "中古";
      }

      if (this.$route.path.indexOf("/mansion") != -1) {
        this.buildType = "マンション";
      } else if (this.$route.path.indexOf("/kodate") != -1) {
        this.buildType = "一戸建";
      }

      if (this.$route.path.indexOf("/rent") != -1) {
        this.tradeType = "賃貸";
      } else {
        this.tradeType = "売買";
      }
    },
  },
  watch: {
    bPrice() {
      this.$emit("priceChange", this.bPrice, this.tPrice);
    },
    tPrice() {
      this.$emit("priceChange", this.bPrice, this.tPrice);
    },
    owner(val) {
      this.$emit("ownerChange", val);
    },
    empty(val) {
      this.$emit("emptyChange", val);
    },
    area1(val) {
      this.$emit("area1Change", val);
    },
    year(val) {
      this.$emit("yearChange", val);
    },
    City() {
      if (this.City.indexOf(true) > 0 || this.City.indexOf(true) === 0) {
        this.selec = true;
      } else {
        this.selec = false;
      }
    },
    async $route() {
      this.reset();
      this.priceChange();
      this.routeChange();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/parts/narrow.scss";
</style>
