<template>
  <div>
    <v-dialog v-model="dialog" width="1400">
      <div
        class="fixed w-100vw h-100vh search_loading flex-center z-2 t-0 l-0"
        v-show="loading"
      >
        <div>
          <v-progress-circular
            :size="70"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <div class="f-12 mt-5">読み込み中....</div>
        </div>
      </div>
      <v-card class="city_card" v-show="!loading">
        <div class="title_box text-left mb-4">福岡都市圏エリア</div>
        <v-checkbox
          color="orange"
          class="float-left w300 py-0 my-0"
          v-model="cityAll1"
          :label="
            cityAlls[0] + ' (' + Number(cityAllsCount[0]).toLocaleString() + ')'
          "
          :disabled="cityAllsCount[0] == 0"
        />
        <v-checkbox
          color="orange"
          class="float-left w300 py-0 my-0"
          v-model="cityAll2"
          :label="
            cityAlls[1] + ' (' + Number(cityAllsCount[1]).toLocaleString() + ')'
          "
          :disabled="cityAllsCount[1] == 0"
        />
        <div class="clear" />
        <div class="bar mb-8" />
        <v-checkbox
          v-for="(ci, i) in citys1"
          :key="`a-${i}`"
          v-model="city[i]"
          :label="ci + ' (' + Number(cityCount[i]).toLocaleString() + ')'"
          class="my-0 py-0 mx-2 float-left w250"
          :disabled="cityCount[i] == 0"
        />
        <div class="clear mb-10" />

        <div class="title_box text-left mb-4">北九州エリア全域</div>
        <v-checkbox
          color="orange"
          class="float-left w300 py-0 my-0"
          v-model="cityAll3"
          :label="
            cityAlls[2] + ' (' + Number(cityAllsCount[2]).toLocaleString() + ')'
          "
          :disabled="cityAllsCount[2] == 0"
        />
        <v-checkbox
          color="orange"
          class="float-left w300 py-0 my-0"
          v-model="cityAll4"
          :label="
            cityAlls[3] + ' (' + Number(cityAllsCount[3]).toLocaleString() + ')'
          "
          :disabled="cityAllsCount[3] == 0"
        />
        <div class="clear" />
        <div class="bar mb-8" />
        <v-checkbox
          v-for="(ci, i) in citys2"
          :key="`b-${i}`"
          v-model="city[i + 23]"
          :label="ci + ' (' + Number(cityCount[i + 23]).toLocaleString() + ')'"
          class="my-0 py-0 mx-2 float-left w250"
          :disabled="cityCount[i + 23] == 0"
        />
        <div class="clear mb-10" />

        <div class="rounded submit w700 relative-center text-left mb-10">
          <div class="f-11 bold float-left relative kensu">該当件数</div>
          <div class="clear dis-xxs mb-5" />
          <div
            class="noto9 f-26 f-22-xxs f-20-xxxs f-red w200 float-left text-right count"
          >
            {{ Number(allCount).toLocaleString() }}
          </div>
          <div class="f-11 bold float-left relative kensu pl-2">件</div>
          <button
            class="rounded-pill box-shadow submit_btn float-left w300 f-14 f-12-xxs relative"
            @click="submit"
          >
            <span class="disno-xxs">
              <v-icon class="submit_i f-16 pr-5 reative">fas fa-home</v-icon>
            </span>
            物件を見る
          </button>
          <div class="clear" />
        </div>

        <div class="title_box text-left">筑豊エリア全域</div>
        <v-checkbox
          color="orange"
          class="float-left w300"
          v-model="cityAll5"
          :label="
            cityAlls[4] + ' (' + Number(cityAllsCount[4]).toLocaleString() + ')'
          "
          :disabled="cityAllsCount[4] == 0"
        />
        <div class="clear" />
        <div class="bar mb-8" />
        <v-checkbox
          v-for="(ci, i) in citys3"
          :key="`c-${i}`"
          v-model="city[i + 42]"
          :label="ci + ' (' + Number(cityCount[i + 42]).toLocaleString() + ')'"
          class="my-0 py-0 mx-2 float-left w250"
          :disabled="cityCount[i + 42] == 0"
        />
        <div class="clear mb-10" />

        <div class="title_box text-left">筑後エリア全域</div>
        <v-checkbox
          color="orange"
          class="float-left w300"
          v-model="cityAll6"
          :label="
            cityAlls[5] + ' (' + Number(cityAllsCount[5]).toLocaleString() + ')'
          "
          :disabled="cityAllsCount[5] == 0"
        />
        <div class="clear" />
        <div class="bar mb-8" />
        <v-checkbox
          v-for="(ci, i) in citys4"
          :key="`d-${i}`"
          v-model="city[i + 57]"
          :label="ci + ' (' + Number(cityCount[i + 57]).toLocaleString() + ')'"
          class="my-0 py-0 mx-2 float-left w250"
          :disabled="cityCount[i + 57] == 0"
        />
        <div class="clear mb-10" />

        <div class="rounded submit w700 relative-center text-left mb-10">
          <div class="f-11 bold float-left relative kensu">該当件数</div>
          <div class="clear dis-xxs mb-5" />
          <div
            class="noto9 f-26 f-22-xxs f-20-xxxs f-red w200 float-left text-right count"
          >
            {{ Number(allCount).toLocaleString() }}
          </div>
          <div class="f-11 bold float-left relative kensu pl-2">件</div>
          <button
            class="rounded-pill box-shadow submit_btn float-left w300 f-14 f-12-xxs relative"
            @click="submit"
          >
            <span class="disno-xxs">
              <v-icon class="submit_i f-16 pr-5 reative">fas fa-home</v-icon>
            </span>
            物件を見る
          </button>
          <div class="clear" />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import CityMix from "@/mixins/city";

export default {
  mixins: [CityMix],
  data() {
    return {
      dialog: false,
      cityAlls: [
        "福岡都市圏エリア全域",
        "福岡市全域",
        "北九州エリア全域",
        "北九州市全域",
        "筑豊エリア全域",
        "筑後エリア全域",
      ],
      citys1: [],
      citys2: [],
      citys3: [],
      citys4: [],
      cityAll1: false,
      cityAll2: false,
      cityAll3: false,
      cityAll4: false,
      cityAll5: false,
      cityAll6: false,
      cityCount: [],
      cityAllsCount: [],
      loading: true,
      allCount: 0,
      type: "",
      tradeType: "",
      newOld: "",
      buildType: "",
    };
  },

  async mounted() {
    this.citySet();
  },
  methods: {
    async citySet() {
      this.loading = true;
      if (this.$route.path.indexOf("/buy") != -1) {
        this.tradeType = "売買";
      } else {
        this.tradeType = "賃貸";
      }

      if (this.$route.path.indexOf("/new") != -1) {
        this.newOld = "新築";
      } else {
        this.newOld = "中古";
      }

      if (this.$route.path.indexOf("/mansion") != -1) {
        this.buildType = "マンション";
      } else if (this.$route.path.indexOf("/kodate") != -1) {
        this.buildType = "一戸建";
      } else if (this.$route.path.indexOf("/sumai") != -1) {
        this.buildType = "住まい";
      } else if (this.$route.path.indexOf("/store") != -1) {
        this.buildType = "店舗・事務所";
      }

      const data = {
        tradeType: this.tradeType,
        newOld: this.newOld,
        buildType: this.buildType,
      };
      const response = await axios.post("/estate/city_load", data);
      this.citys1 = response.data.citys1;
      this.citys2 = response.data.citys2;
      this.citys3 = response.data.citys3;
      this.citys4 = response.data.citys4;

      this.cityCount = response.data.cityCount;
      let cityAllsCount = [0, 0, 0, 0, 0, 0];

      for (let i = 0; i < this.citys1.length; i++) {
        cityAllsCount[0] += this.cityCount[i];
      }
      for (let i = 0; i < 7; i++) {
        cityAllsCount[1] += this.cityCount[i];
      }
      for (let i = 23; i < 42; i++) {
        cityAllsCount[2] += this.cityCount[i];
      }
      for (let i = 23; i < 30; i++) {
        cityAllsCount[3] += this.cityCount[i];
      }
      for (let i = 42; i < 57; i++) {
        cityAllsCount[4] += this.cityCount[i];
      }
      for (let i = 57; i < 72; i++) {
        cityAllsCount[5] += this.cityCount[i];
      }

      this.cityAllsCount = cityAllsCount;
      this.count();
      this.loading = false;
    },
    async submit() {
      await this.$emit("cityChange", this.city);
      this.dialog = false;
    },
    count() {
      let allCount = 0;
      if (this.city.indexOf(true) > 0 || this.city.indexOf(true) === 0) {
        for (let i = 0; i < 72; i++) {
          if (this.city[i]) {
            allCount += this.cityCount[i];
          }
        }
      } else {
        for (let i = 0; i < 72; i++) {
          allCount += this.cityCount[i];
        }
      }
      this.allCount = allCount;
    },
  },
  watch: {
    cityAll1(val) {
      for (let i = 0; i < 23; i++) {
        this.city[i] = val;
      }

      this.city.splice();
    },
    cityAll2(val) {
      for (let i = 0; i < 7; i++) {
        this.city[i] = val;
      }

      this.city.splice();
    },
    cityAll3(val) {
      for (let i = 23; i < 42; i++) {
        this.city[i] = val;
      }

      this.city.splice();
    },
    cityAll4(val) {
      for (let i = 23; i < 30; i++) {
        this.city[i] = val;
      }

      this.city.splice();
    },
    cityAll5(val) {
      for (let i = 42; i < 57; i++) {
        this.city[i] = val;
      }

      this.city.splice();
    },
    cityAll6(val) {
      for (let i = 57; i < 72; i++) {
        this.city[i] = val;
      }

      this.city.splice();
    },
    city() {
      this.count();
    },
    async $route() {
      this.cityAll1 = false;
      this.cityAll2 = false;
      this.cityAll3 = false;
      this.cityAll4 = false;
      this.cityAll5 = false;
      this.cityAll6 = false;
      this.citySet();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/parts/city.scss";
</style>
